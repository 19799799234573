import React, { FC } from "react";
import { Layout } from "../components/layout";
import { Center } from "../components/center";
import { ContactPageTemplateQuery } from "../../graphql-types";
import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

type Props = {
  data: ContactPageTemplateQuery;
};

export const query = graphql`
  query ContactPageTemplate($id: ID!) {
    wp {
      page(id: $id, idType: ID) {
        featuredImage {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPageTemplate: FC<Props> = props => {
  return (
    <Layout showServicesBlock={false}>
      <div className="background-stripes">
        <Center>
          <div className="grid grid-cols-1 lg:grid-cols-2 pb-5 lg:py-20">
            <div>
              <div className="bg-dodger-blue text-white py-8 pr-8 lg:py-10 lg:pr-10 relative -mr-5 lg:mr-0">
                <div
                  className="bg-dodger-blue absolute top-0 bottom-0 right-0 "
                  style={{ left: "-9999px" }}
                ></div>

                <div className="relative">
                  <h1 className="text-white">Contact met Mira VvE-Beheer</h1>

                  <p className="py-5">Bezoekadres (uitsluitend op afspraak):</p>

                  <p>
                    Mira Vve-Beheer
                    <br />
                    Oosterbacht 4b
                    <br />
                    7821 CJ Emmen
                    <br />
                    Tel.:{" "}
                    <a className="text-white" href="tel:+31591679001">
                      0591-679001
                    </a>
                    <br />
                    <a className="text-white" href="mailto:info@miravve.nl">
                      info@miravve.nl
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="lg:pl-10">
              <h3 className="pt-10 lg:pt-5">Klantenservice</h3>

              <p className="pt-5">
                Mira VvE-Beheer is bereikbaar van maandag tot en met vrijdag
                tussen 9.00 uur en 17.00 uur.
              </p>

              <h3 className="pt-10">Storingsmeldingen</h3>

              <p className="pt-5">
                Uitsluitend voor storingsmeldingen kunt u buiten kantoortijden
                bellen met telefoonnummer: 06-23 86 86 00. Mocht er onverhoopt
                geen gehoor zijn, waarschuwt u dan een bestuurslid van uw
                vereniging. Meerdere verenigingen hebben instructies hoe te
                handelen.
              </p>

              {props.data.wp.page?.featuredImage && (
                <div className="pt-10">
                  <Img
                    fluid={
                      props.data.wp.page.featuredImage.localFile
                        ?.childImageSharp?.fluid as FluidObject
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Center>
      </div>
    </Layout>
  );
};

export default ContactPageTemplate;
